import {
    REQUEST,
    VOUCHERS_TYPES_SET,
    VOUCHERS_TYPES_FETCH,
    VOUCHERS_SETTINGS_FETCH,
    VOUCHERS_SETTINGS_SET,
    VOUCHERS_SETTINGS_UPDATE,
    SELECTED_LANGUAGE,
    VOUCHERS_CLIENT_FETCH,
} from '@/constants';

import i18n from '@/languages/index.js';

export default {
    state: {
        voucherTypes: null,
        voucherSettings: null,
        durations: [
            {
                label:
                    '30 ' +
                    i18n.t(
                        'professional.vouchers.modal.minutes',
                        localStorage.getItem(SELECTED_LANGUAGE),
                    ),
                value: 30,
            },
            {
                label:
                    '45 ' +
                    i18n.t(
                        'professional.vouchers.modal.minutes',
                        localStorage.getItem(SELECTED_LANGUAGE),
                    ),
                value: 45,
            },
            {
                label:
                    '60 ' +
                    i18n.t(
                        'professional.vouchers.modal.minutes',
                        localStorage.getItem(SELECTED_LANGUAGE),
                    ),
                value: 60,
            },
            {
                label:
                    '90 ' +
                    i18n.t(
                        'professional.vouchers.modal.minutes',
                        localStorage.getItem(SELECTED_LANGUAGE),
                    ),
                value: 90,
            },
            {
                label:
                    '120 ' +
                    i18n.t(
                        'professional.vouchers.modal.minutes',
                        localStorage.getItem(SELECTED_LANGUAGE),
                    ),
                value: 120,
            },
        ],
    },
    mutations: {
        [VOUCHERS_TYPES_SET](state, voucherTypes) {
            state.voucherTypes = voucherTypes.map((type) => {
                return {
                    ...type,
                    label: type.type,
                    value: type.id,
                };
            });
        },
        [VOUCHERS_SETTINGS_SET](state, voucherSettings) {
            state.voucherSettings = voucherSettings;
        },
    },
    actions: {
        async [VOUCHERS_TYPES_FETCH]({ commit, dispatch }) {
            const response = await dispatch(REQUEST, {
                url: 'voucher-types',
            });
            if (response.status === 200) {
                commit(VOUCHERS_TYPES_SET, response.data.data);
            }
            return response;
        },
        async [VOUCHERS_SETTINGS_FETCH]({ commit, dispatch }) {
            const response = await dispatch(REQUEST, {
                url: 'vouchers-settings',
            });
            if (response.status === 200) {
                commit(VOUCHERS_SETTINGS_SET, response.data.data);
            }
            return response;
        },
        async [VOUCHERS_SETTINGS_UPDATE]({ dispatch }, voucherSettings) {
            const response = await dispatch(REQUEST, {
                url: 'vouchers-settings',
                method: 'put',
                data: { voucher_settings: voucherSettings },
            });

            if (response.status === 200) {
                await dispatch(VOUCHERS_SETTINGS_FETCH);
            }
        },

        async [VOUCHERS_CLIENT_FETCH]({ dispatch }, params) {
            const response = await dispatch(REQUEST, {
                url: 'vouchers',
                method: 'get',
                params,
            });

            return response;
        },
    },

    getters: {
        getVoucherById: (state) => (id) => state.voucherTypes.find((el) => el.id === id),
    },
};
